import React from 'react';
import { useTranslation } from 'react-i18next';
import { UIBox } from '@/components/ui/Box';
import { UIButtonWithIcon } from '@/components/ui/Button';
import RefreshIcon from '@material-ui/icons/Refresh';

import * as S from './style';
import { Typography } from '@/components/ui/Typography';
import { UIAction } from '@/components/ui/Action';
import {
  DeleteMassiveLoaderStoreResponse,
  LoadStoresRestService,
} from '@/api/receive';
import { ProcessesService } from '@/api/process';
import useHandleProcess from '@/hooks/useHandleProcess';
import { useHistory } from 'react-router';
import { AppRoutes } from '@/app/routers';
import { useAsync } from 'react-use';
import { PageLoader } from '@/components/ui/PageLoader';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useAppDispatch } from '@/app/store';
import { setProcessLocked } from '@/features/loader/loaderSlice';
import { useSelector } from '@/hooks/useSelector';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { PaginationList } from '@/components/layout/PaginationList';

type Stores = NonNullable<DeleteMassiveLoaderStoreResponse['stores']>;

const PageDeleteEpc: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const lockProcess = useHandleProcess();
  const deleteProcessLocked = useSelector(
    state => state.loader.deleteProcessLocked
  );

  const [fetchStoresLoading, setFetchStoresLoading] =
    React.useState<boolean>(false);
  const [fetchStoresError, setFetchStoresError] =
    React.useState<boolean>(false);

  const [stores, setStores] = React.useState<Stores>([]);
  const [searchedStore, setSearchedStore] = React.useState<string>();
  const [filteredStores, setFilteredStores] = React.useState<Stores>([]);

  const fetchStores = React.useCallback(async () => {
    try {
      setFetchStoresLoading(true);

      const response = await LoadStoresRestService.loadstoresRecapStoreUploaded(
        {}
      );

      if (response && response.stores) {
        setStores(response.stores);
        setFilteredStores(response.stores);
      }

      setFetchStoresLoading(false);
    } catch {
      setFetchStoresError(true);
      setFetchStoresLoading(false);
    }
  }, []);

  useAsync(async () => {
    if (deleteProcessLocked) {
      dispatch(
        setProcessLocked({ process: 'deleteProcessLocked', locked: false })
      );

      await lockProcess({ process: 'DSUP', isLocked: false });
    }
  }, []);

  useAsync(async () => {
    await fetchStores();
  }, []);

  React.useEffect(() => {
    if (searchedStore) {
      setFilteredStores(prevState =>
        prevState.filter(storeCode =>
          storeCode.toLowerCase().includes(searchedStore.toLowerCase())
        )
      );
    } else {
      setFilteredStores(stores);
    }
  }, [searchedStore, stores]);

  const handleDetailsClick = async (storeCode: string): Promise<void> => {
    try {
      const process =
        (await ProcessesService.readProcessStatus({
          storeCode,
          process: 'DSUP',
          filterByStoreCode: true,
        })) || [];

      if (process && process[0].isLocked) {
        return;
      }

      await lockProcess({ process: 'DSUP', isLocked: true });

      dispatch(
        setProcessLocked({ process: 'deleteProcessLocked', locked: true })
      );

      history.push(`${AppRoutes.DELETE_EPC}/${storeCode}`);
    } catch {}
  };

  return (
    <>
      {fetchStoresLoading ? (
        <PageLoader />
      ) : (
        <>
          <UIBox flexDirection="column" width="100%" p={3}>
            <UIBox
              mt={3}
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <S.SearchBar
                label=""
                value={searchedStore}
                placeholder={t('profiling.filterByStoreCode')}
                disabled={false}
                onSearch={(): void => {}}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setSearchedStore(e.target.value)
                }
                loading={false}
                hideButton={true}
              />
              <UIButtonWithIcon
                label={t('refresh')}
                startIcon={<RefreshIcon />}
                onClick={fetchStores}
              />
            </UIBox>
            {stores.length > 0 ? (
              <UIBox flexDirection="column" mt={5} mb="100px">
                <S.HeaderList>
                  <Typography font="heavy">{t('storeCode')}</Typography>
                </S.HeaderList>
                <S.List>
                  <PaginationList
                    data={filteredStores}
                    pageSize={25}
                    renderItem={(storeCode: string): JSX.Element => (
                      <UIBox justifyContent="space-between" p={2}>
                        <Typography>{storeCode}</Typography>
                        <UIAction
                          icon="search"
                          label={t('details')}
                          onClick={(): Promise<void> =>
                            handleDetailsClick(storeCode)
                          }
                        />
                      </UIBox>
                    )}
                  />
                </S.List>
              </UIBox>
            ) : (
              <UIBox mt="100px" alignItems="center" justifyContent="center">
                <Typography font="heavy" size="lg">
                  {t('anyLoaderItems')}
                </Typography>
              </UIBox>
            )}
          </UIBox>
          <CTAContainer
            type="BACK"
            onClick={(): void => history.push(AppRoutes.STORE_MIGRATOR)}
          />
        </>
      )}
      <ErrorSnackbar
        open={fetchStoresError}
        setIsOpen={setFetchStoresError}
        errorMessage={t('error.loadingStoreCodes')}
      />
    </>
  );
};

export default PageDeleteEpc;
