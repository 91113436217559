import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAsync, useDebounce } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import { DateTime } from 'luxon';

import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';

import { UIBox } from '@/components/ui/Box';
import { UICheckbox } from '@/components/ui/Checkbox';
import { UIAction } from '@/components/ui/Action';
import { Autocomplete } from '@material-ui/lab';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { Typography } from '@/components/ui/Typography';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { PageLoader } from '@/components/ui/PageLoader';

import { ReceiveShipmentsService } from '@/api';
import {
  StyledArrowDropDownIcon,
  StyledDateWrapper,
  StyledField,
  StyledSearchBarLight,
  StyledStartAdornament,
  StyledStatus,
  StyledTablesHeader,
  StyledTablesRow,
  StyledTableWrapper,
} from './style';
import type { DeliveryStatus, Filters } from './types';
import { GetDeliveriesRecapRow, StoreService } from '@/api/receive';
import { ProcessesService, ProcessStatus } from '@/api/process';

import { AppRoutes } from '@/app/routers';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import {
  setDeliveryStatuses,
  setManagedDeliveryInUse,
  setSearchedDeliveryCode,
  setSearchedDeliveryCodes,
  setSearchedStoreCode,
  setSearchedStoreCodes,
  setShouldFetchDeliveries,
} from '@/features/deliveriesManage/deliveriesManageSlice';
import { UIButton } from '@/components/ui/Button';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';

const _uniqueArray = (array: string[]): string[] => [
  ...new Set([...array].filter(value => value !== '')),
];

const handleDeliveryStatus = (status: DeliveryStatus): JSX.Element => {
  switch (status) {
    case 'New':
      return (
        <UIBox alignItems="center">
          <StyledStatus color="#417505" />
          <Typography>{status}</Typography>
        </UIBox>
      );

    case 'In Use':
      return (
        <UIBox alignItems="center">
          <StyledStatus color="#F6BB3B" />
          <Typography>{status}</Typography>
        </UIBox>
      );

    default:
      return (
        <UIBox alignItems="center">
          <StyledStatus color="#E20404" />
          <Typography>{status}</Typography>
        </UIBox>
      );
  }
};

const PageManageDelivery: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {
    searchedStoreCode,
    searchedStoreCodes,
    searchedDeliveryCode,
    searchedDeliveryCodes,
    deliveryManagedInUse,
    deliveryStatuses,
    shouldFetchDeliveries,
  } = useSelector(state => state.manageDeliveries);

  const [storeCodes, setStoreCodes] = useState<string[]>([]);
  const [deliveries, setDeliveries] = useState<GetDeliveriesRecapRow[]>([]);
  const [deliveriesSorted, setDeliveriesSorted] = useState<boolean>(false);
  const [deliveryNumber, setDeliveryNumber] = useState<string>();
  const [storeCode, setStoreCode] = useState<string>();

  const [fetchDeliveries, setFetchDeliveries] = useState<boolean>(false);
  const [unlockModalIsVisible, setUnlockModalVisibility] =
    useState<boolean>(false);
  const [backToStoreModalIsVisible, setBackToStoreModalVisibility] =
    useState<boolean>(false);
  const [disableStoreCodeInput, setDisableStoreCodeInput] =
    useState<boolean>(false);
  const [disableDeliveryCodeInput, setDisableDeliveryCodeInput] =
    useState<boolean>(false);
  const [fetchStoreCodesError, setFetchStoreCodesError] =
    useState<boolean>(false);
  const [fetchDeliveriesError, setFetchDeliveriesError] =
    useState<boolean>(false);
  const [downloadReportError, setDownloadReportError] =
    useState<boolean>(false);
  const [unlockDeliveryError, setUnlockDeliveryError] =
    useState<boolean>(false);
  const [backToStoreError, setBackToStoreError] = useState<boolean>(false);
  const [noReportAvailable, setNoReportAvailable] = useState<boolean>(false);

  useAsync(async () => {
    /**
     * It runs only if the user go back from the delivery details page
     * (with chrome back arrow)
     */
    if (deliveryManagedInUse) {
      await ReceiveShipmentsService.receiveshipChangeDeliveryStatus({
        requestBody: {
          deliveryNumbers: [deliveryManagedInUse],
          status: 'New',
        },
      });

      dispatch(setManagedDeliveryInUse(undefined));
      setFetchDeliveries(true);
    }
  }, []);

  useAsync(async () => {
    try {
      const response = await StoreService.storeserviceGetWholeStoreCodes({
        isActive: false,
      });

      if (response && response.storeCodes) {
        setStoreCodes(response.storeCodes);
      }
    } catch {
      setFetchStoreCodesError(true);
    }
  }, []);

  useAsync(async () => {
    /**
     * shouldFetchDeliveries is true only if the user go back (with  back  button)
     * for confirm from the delivery details page
     * */
    if (fetchDeliveries || shouldFetchDeliveries) {
      try {
        if (shouldFetchDeliveries) {
          dispatch(setShouldFetchDeliveries(false));
        }

        if (searchedStoreCodes.length > 0 || searchedDeliveryCodes.length > 0) {
          const response =
            await ReceiveShipmentsService.receiveshipGetDeliveriesRecap({
              requestBody: {
                storeCodes: searchedStoreCodes,
                deliveryNumbers: searchedDeliveryCodes,
                deliveryStatuses,
              },
            });

          if (response.deliveryList) {
            const { deliveryList } = response;

            setFetchDeliveries(false);
            return setDeliveries(deliveryList);
          }
        }
      } catch {
        setFetchDeliveriesError(true);
      }

      setFetchDeliveries(false);
      setDeliveries([]);
    }
  }, [fetchDeliveries]);

  useEffect(() => {
    if (searchedStoreCode !== '') {
      setDisableDeliveryCodeInput(true);
    } else {
      dispatch(setSearchedStoreCodes([]));
      setDisableDeliveryCodeInput(false);
    }
  }, [dispatch, searchedStoreCode]);

  useDebounce(
    () => {
      if (searchedDeliveryCode !== '') {
        const uniqueDeliveryCodes = _uniqueArray([searchedDeliveryCode]);

        dispatch(setSearchedDeliveryCodes(uniqueDeliveryCodes));
        dispatch(setSearchedStoreCodes([]));
        setDisableStoreCodeInput(true);
        setFetchDeliveries(true);
      } else {
        setDisableStoreCodeInput(false);
        dispatch(setSearchedDeliveryCodes([]));
      }
    },
    1250,
    [searchedDeliveryCode]
  );

  const changeStoreCodeHandler = (value: string | null): void => {
    const storeCode = value || '';

    if (storeCode !== '') {
      const uniqueStoreCodes = _uniqueArray([storeCode]);

      dispatch(setSearchedStoreCode(storeCode));
      dispatch(setSearchedStoreCodes(uniqueStoreCodes));
      setFetchDeliveries(true);
    } else {
      dispatch(setSearchedStoreCode(''));
    }
  };

  const changeDeliveryCodeHandler = (deliveryCode: string): void => {
    dispatch(setSearchedDeliveryCode(deliveryCode));
  };

  const downloadReport = async (): Promise<void> => {
    try {
      const response =
        await ReceiveShipmentsService.receiveshipDownLoadReport();

      if (!response) {
        return setNoReportAvailable(true);
      }

      const linkSource = `data:application/csv;base64,${response.csvFile}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = response.nameFile;
      downloadLink.click();
    } catch {
      setDownloadReportError(true);
    }
  };

  const checkboxFilterHandler = (status: DeliveryStatus): void => {
    dispatch(setDeliveryStatuses(status));
    setFetchDeliveries(true);
  };

  const [onGoingModalIsVisible, setOnGoingModalVisibility] =
    useState<boolean>(false);
  const [processLock, setProcessLock] = useState<ProcessStatus>();

  const checkProcessOnGoing = async (storeCode: string): Promise<boolean> => {
    const bisInProgress =
      (await ProcessesService.readProcessStatus({
        storeCode: storeCode,
        filterByStoreCode: true,
        process: 'BIS',
      })) || [];

    if (bisInProgress && bisInProgress[0].isLocked) {
      setProcessLock(bisInProgress[0]);
      setOnGoingModalVisibility(true);
      return true;
    }
    return false;
  };

  const detailsClickHandler = async (
    storeCode: string,
    deliveryNumber: string
  ): Promise<void> => {
    const lockedProcess = await checkProcessOnGoing(storeCode);
    if (!lockedProcess) {
      history.push(
        `${AppRoutes.MANAGE_DELIVERY}/${storeCode}/${deliveryNumber}`
      );
    }
  };

  // BACK TO STORE ACTIONS -----------------
  const backToStoreDelivery = async (): Promise<void> => {
    try {
      if (deliveryNumber && storeCode) {
        await ReceiveShipmentsService.receiveshipBackToStore({
          requestBody: {
            deliveryNumber,
            storeCode,
          },
        });

        setDeliveryNumber(undefined);
        setStoreCode(undefined);
        setFetchDeliveries(true);
        setBackToStoreModalVisibility(false);
      }
    } catch {
      setBackToStoreError(true);
    }
  };

  const backToStoreClickHandler = (
    deliveryNumber: string,
    storeCode: string
  ): void => {
    setDeliveryNumber(deliveryNumber);
    setStoreCode(storeCode);
    setBackToStoreModalVisibility(true);
  };

  const backToStoreModalCloseHandler = (): void => {
    setDeliveryNumber(undefined);
    setStoreCode(undefined);
    setBackToStoreModalVisibility(false);
  };
  // ---------------------------------------
  // ---------------------------------------

  // UNLOCK ACTIONS ------------------------
  const unlockDelivery = async (): Promise<void> => {
    try {
      await ReceiveShipmentsService.receiveshipChangeDeliveryStatus({
        requestBody: {
          status: 'New',
          deliveryNumbers: [deliveryNumber || ''],
        },
      });

      setDeliveryNumber(undefined);
      setFetchDeliveries(true);
      setUnlockModalVisibility(false);
    } catch {
      setUnlockDeliveryError(true);
    }
  };

  const unlockClickHandler = (deliveryNumber: string): void => {
    setDeliveryNumber(deliveryNumber);
    setUnlockModalVisibility(true);
  };

  const unlockModalCloseHandler = (): void => {
    setDeliveryNumber(undefined);
    setUnlockModalVisibility(false);
  };
  // ---------------------------------------
  // ---------------------------------------

  const handleDeliveryAction = (
    status: DeliveryStatus,
    storeCode: string,
    deliveryNumber: string
  ): JSX.Element => {
    switch (status) {
      case 'New':
        return (
          <UIAction
            label={t('details')}
            icon="search"
            onClick={(): Promise<void> =>
              detailsClickHandler(storeCode, deliveryNumber)
            }
          />
        );

      case 'In Use':
        return (
          <UIAction
            label={t('unlock')}
            icon="unlock"
            onClick={(): void => unlockClickHandler(deliveryNumber)}
          />
        );

      default:
        return (
          <UIAction
            label={t('backToStore')}
            icon="backupRestore"
            onClick={(): void =>
              backToStoreClickHandler(deliveryNumber, storeCode)
            }
          />
        );
    }
  };

  const filters: Filters = [
    {
      label: t('New'),
      value: 'New',
      onClick: (): void => checkboxFilterHandler('New'),
    },
    {
      label: t('inUse'),
      value: 'In Use',
      onClick: (): void => checkboxFilterHandler('In Use'),
    },
    {
      label: t('stolen'),
      value: 'Stolen',
      onClick: (): void => checkboxFilterHandler('Stolen'),
    },
  ];

  const dateHandler = (date: string): string => {
    return DateTime.fromFormat(date, 'yyyyMMdd').toFormat('yyyy LLLL dd', {
      locale: i18n.language,
    });
  };

  return (
    <>
      <ModalAttention
        open={unlockModalIsVisible}
        onConfirmClick={unlockDelivery}
        onClose={unlockModalCloseHandler}
        messageMaxWidth="75%"
        message={t('unlockDeliveryMessage')}
      />
      <ModalAttention
        open={backToStoreModalIsVisible}
        onConfirmClick={backToStoreDelivery}
        onClose={backToStoreModalCloseHandler}
        messageMaxWidth="75%"
        message={t('backToStoreDeliveryMessage')}
      />
      <UIBox flexDirection="column" width="100%" padding={3}>
        <UIBox flexDirection="column">
          <Typography>{t('searchStoreCodeOrDeliveryNumber')}</Typography>
          <UIBox mt={2} flexWrap="wrap">
            <Autocomplete
              autoComplete
              key="store-codes"
              value={searchedStoreCode}
              onChange={(_e, code): void => {
                changeStoreCodeHandler(code);
              }}
              disabled={disableStoreCodeInput}
              options={storeCodes}
              getOptionLabel={(option): string => option || ''}
              getOptionSelected={(option, value): boolean => option === value}
              renderInput={(params): JSX.Element => (
                <StyledField
                  {...params}
                  label={t('storeCode')}
                  variant="outlined"
                />
              )}
            />
            <StyledSearchBarLight
              label=""
              value={searchedDeliveryCode}
              placeholder={t('filterByDeliveryNumber')}
              disabled={disableDeliveryCodeInput}
              loading={false}
              hideButton={true}
              onSearch={(): void => {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                changeDeliveryCodeHandler(e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <StyledStartAdornament>
                    <Typography color="grey" size="lg" font="heavy">
                      #
                    </Typography>
                  </StyledStartAdornament>
                ),
              }}
            />
            <UIBox ml={8} flexDirection="column">
              <Typography>{t('filterByStatus')}:</Typography>
              <UIBox mt={1} ml={-1.5}>
                {filters.map(({ label, value, onClick }, index) => (
                  <UIBox alignItems="center" key={`${value}-${index}`}>
                    <UICheckbox
                      checked={deliveryStatuses.includes(
                        value as DeliveryStatus
                      )}
                      onClick={onClick}
                    />
                    <Typography margin="0 16px 0 -4px">{label}</Typography>
                  </UIBox>
                ))}
              </UIBox>
            </UIBox>
          </UIBox>
          {fetchDeliveries ? (
            <PageLoader />
          ) : (
            <>
              {deliveries.length > 0 ? (
                <StyledTableWrapper>
                  <StyledTablesHeader>
                    <Typography font="heavy">{t('storeCode')}</Typography>
                    <Typography font="heavy">{t('delivery')} #</Typography>
                    <StyledDateWrapper
                      onClick={(): void =>
                        setDeliveriesSorted(prevState => !prevState)
                      }
                    >
                      <Typography font="heavy">{t('arrivalDate')}</Typography>
                      <StyledArrowDropDownIcon $rotated={deliveriesSorted} />
                    </StyledDateWrapper>
                    <Typography font="heavy">
                      {t('shipmentQuantity')}
                    </Typography>
                    <Typography font="heavy">
                      {t('expectedQuantity')}
                    </Typography>
                    <Typography font="heavy">{t('status')}</Typography>
                  </StyledTablesHeader>
                  {deliveries
                    .sort((a, b) =>
                      deliveriesSorted
                        ? Number(a.arrivalDate) - Number(b.arrivalDate)
                        : Number(b.arrivalDate) - Number(a.arrivalDate)
                    )
                    .map(
                      (
                        {
                          storeCode,
                          deliveryNumber,
                          arrivalDate,
                          shipmentQuantity,
                          expectedQuantity,
                          deliveryStatus,
                        },
                        index
                      ) => (
                        <StyledTablesRow key={`${deliveryNumber}-${index}`}>
                          <Typography>{storeCode}</Typography>
                          <Typography>{deliveryNumber}</Typography>
                          {arrivalDate && (
                            <Typography>{dateHandler(arrivalDate)}</Typography>
                          )}
                          <Typography>{shipmentQuantity}</Typography>
                          <Typography>{expectedQuantity}</Typography>
                          {handleDeliveryStatus(
                            deliveryStatus as DeliveryStatus
                          )}
                          {storeCode && deliveryNumber && (
                            <>
                              {handleDeliveryAction(
                                deliveryStatus as DeliveryStatus,
                                storeCode,
                                deliveryNumber
                              )}
                            </>
                          )}
                        </StyledTablesRow>
                      )
                    )}
                </StyledTableWrapper>
              ) : (
                <>
                  {searchedStoreCode !== '' && (
                    <UIBox mt={3}>
                      <Typography font="heavy" size="lg" color="grey">
                        {t('deliveriesUnavailableForTheSelectedStore')}
                      </Typography>
                    </UIBox>
                  )}
                </>
              )}
            </>
          )}
        </UIBox>
        <CTAContainer
          type="TWO_BUTTONS"
          onClick={downloadReport}
          onBackClick={(): void => history.push(AppRoutes.INTRO)}
          disabledMainAction={false}
          mainButtonLabel={t('downloadReport')}
        />
        <ErrorSnackbar
          open={fetchStoreCodesError}
          setIsOpen={setFetchStoreCodesError}
          errorMessage={t('error.loadingStoreCodes')}
          ClickAwayListenerProps={{
            onClickAway: (): void => setFetchStoreCodesError(false),
          }}
        />
        <ErrorSnackbar
          open={fetchDeliveriesError}
          setIsOpen={setFetchDeliveriesError}
          errorMessage={t('error.loadingDeliveries')}
          ClickAwayListenerProps={{
            onClickAway: (): void => setFetchDeliveriesError(false),
          }}
        />
        <ErrorSnackbar
          open={downloadReportError}
          setIsOpen={setDownloadReportError}
          errorMessage={t('error.downloadReport')}
          ClickAwayListenerProps={{
            onClickAway: (): void => setDownloadReportError(false),
          }}
        />
        <ErrorSnackbar
          open={unlockDeliveryError}
          setIsOpen={setUnlockDeliveryError}
          errorMessage={t('error.unlockDelivery')}
          ClickAwayListenerProps={{
            onClickAway: (): void => setUnlockDeliveryError(false),
          }}
        />
        <ErrorSnackbar
          open={backToStoreError}
          setIsOpen={setBackToStoreError}
          errorMessage={t('error.backToStoreDelivery')}
          ClickAwayListenerProps={{
            onClickAway: (): void => setBackToStoreError(false),
          }}
        />
        <AlertSnackbar
          open={noReportAvailable}
          setIsOpen={setNoReportAvailable}
          message={t('warning.noReportAvailable')}
        />

        <ModalDataSavedError
          iconType="ERROR"
          $minWidth="705px"
          $minHeight="260px"
          title={t('payAttention')}
          message={t('manageDeliveryDisabled', {
            process: t(processLock?.process || ''),
            lockedBy: processLock?.lockedBy || '',
            store: storeCode || '',
          })}
          open={onGoingModalIsVisible}
          onClose={(): void => {
            setOnGoingModalVisibility(false);
          }}
        >
          <UIBox mt={4} alignItems="center" width="50%">
            <UIButton
              outlined
              label={t('quit')}
              onClick={(): void => setOnGoingModalVisibility(false)}
            />
          </UIBox>
        </ModalDataSavedError>
      </UIBox>
    </>
  );
};

export default PageManageDelivery;
