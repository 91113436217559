import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { isRFIDStore } from '@/utils/libs';
// import { ModalReceivingNoRfid } from '@/components/layout/ModalReceivingNoRfid';

import {
  // DeliveryItems,
  ProductItemForPrint,
  ScanDevicesService,
} from '@/api';

import {
  confirmDelivery,
  getScannedProducts,
} from '@/features/products/scannedProductsSlice';
import { deleteTags, tagsMQTTDevice } from '@/features/devices/devicesSlice';

import { Typography } from '@/components/ui/Typography';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { UILoader } from '@/components/ui/Loader';
import { UIButton } from '@/components/ui/Button';
import { ModalProductDetails } from '@/components/layout/ModalProductDetails';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';
import StatusBar from './StatusBar';

import { EnumMode, PrintReasonCodeStatus } from '@/types/enum';

import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { UIBox } from '@/components/ui/Box';
import ProductList from './ProductList';

import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';

import { DateTime } from 'luxon';
import { useSubscribeEpcs } from '@/hooks/mqtt/useSubscribeEpcs';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';

import { StyledProductsWrapper } from './style';

import { Product, ProductValues } from '@/types/enum';
import type { ScannedProduct } from '@/types/product';
import { useAsync } from 'react-use';
import { initDeliveryList } from '@/features/deliveries/deliveriesSlice';
import { initDeliveryProducts } from '@/features/products/deliveryProductsSlice';
import { ModalAttention } from '@/components/layout/ModalAttention';

import ModalPrintTemp from '@/components/layout/ModalPrintTemp';
import { printLabel } from '@/features/print/printSlice';
import { usePrintContext } from '@/context/Print';
import { ReceivingRedux } from '../actions';
import useTrackPrint from '../track/useTrackPrint';
import useTrackConfirm from '../track/useTrackConfirm';
import useTrackInvalidUpc from '../track/useTrackInvalidUpc';
import { handlePrintLayout } from '@/utils/handlePrintLayout';
import { ProductTable } from './ProductTable';
import { SearchBarLight } from '@/components/layout/SearchBar/SearchBarLight';
import { ScannedProductFilters } from '@/types/filters';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { ProcessesService, ProcessStatus } from '@/api/process';
import { setAvoidAlertInHomePage } from '@/features/bis/backInStockSlice';

const PageReceivingScan: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const history = useHistory();

  const { print, setPrint } = usePrintContext();
  const [scanAgainCounter, setScanAgainCounter] = useState<number>(1);
  const [startDeliveryTime] = useState<DateTime>(DateTime.local());

  // * UI STATE
  const [selectedTab, setSelectedTab] = useState<ProductValues | undefined>(
    Product.NOT_FOUND
  );
  const [inPause, setPause] = useState(false);
  const [noPrinterAvailable, setNoPrinterAvailable] = useState<boolean>(false);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();
  const [confirmError, setConfirmError] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [printTagError, setPrintTagError] = useState<boolean>(false);
  const [confirmModalIsVisibile, setConfirmModalVisibility] =
    useState<boolean>(false);

  const [isWarningModalVisible, setWarningModalVisibility] =
    useState<boolean>(false);

  const [finishButtonClicked, setFinishButtonClicked] =
    useState<boolean>(false);

  const [confirmButtonClicked, setConfirmButtonClicked] =
    useState<boolean>(false);

  // ---------------------------------------------------------------- //

  // * MODALS STATE
  // ** Product details modal
  const [openProductModal, setProductModalVisibility] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');

  // ** Scan details modal
  const [openScanModal, setScanModalVisibility] = useState(false);

  // ** Print details modal
  const [openPrintModal, setPrintModalVisibility] = useState(false);
  // const [openPrintNoRfidModal, setPrintNoRfidModalVisibility] = useState(false);
  const [printInError, setPrintInError] = useState<boolean>(false);

  const [productToPrint, setProductToPrint] = useState<ProductItemForPrint>();

  // ---------------------------------------------------------------- //

  // * FUNCTIONALITIES STATE
  const [isScanningAgain, setIsScanningAgain] = useState<boolean>(false);
  const [isGettingTags, setIsGettingTags] = useState<boolean>(true);
  const [isScanCompleted, setIsScanCompleted] = useState<boolean>(false);
  const [view, setView] = useState<string>('list');
  const [onGoingModalIsVisible, setOnGoingModalVisibility] =
    useState<boolean>(false);
  const [processLock, setProcessLock] = useState<ProcessStatus>();
  // ---------------------------------------------------------------- //

  // * SELECTORS
  const { tags } = useSelector(state => state.devices);
  const { printEpcHasError: printTagHasError } = useSelector(
    state => state.scannedProducts
  );
  const { deviceInUse, deleteTagsHasError } = useSelector(
    state => state.devices
  );
  const { selectedDeliveries } = useSelector(state => state.deliveries);

  const subscribeEPCS = useSubscribeEpcs(deviceInUse?.deviceId!);

  const { isLoading } = useSelector(state => state.deliveryProducts);

  const found = useSelector(
    state => state.scannedProducts.products.found.list || []
  );

  const missing = useSelector(
    state => state.scannedProducts.products.missing.list || []
  );

  const unexpected = useSelector(
    state => state.scannedProducts.products.unexpected.list || []
  );

  const sold = useSelector(
    state => state.scannedProducts.products.sold.list || []
  );

  const invalidUpc = useSelector(
    state => state.scannedProducts.products.invalidProduct || []
  );

  const {
    ui: { disableScanAgain },
    // products: {
    //   missing: { epcs: missingEpcs },
    // },
    confirmDeliveryHasError,
  } = useSelector(state => state.scannedProducts);

  const { printError } = useSelector(state => state.print);

  const store = useSelector(state => state.currentStore.store);
  const printers = useSelector(
    state => state.currentStore.store?.printers || []
  );

  const disableActions = isGettingTags || inPause || !isScanCompleted;
  const trackPrint = useTrackPrint();
  const trackConfirm = useTrackConfirm(scanAgainCounter);
  const trackInvalidUpc = useTrackInvalidUpc();

  const [filters, setFilters] = useState<ScannedProductFilters>({
    upcCode: '',
  });

  const onFilterChange = (
    key: keyof ScannedProductFilters,
    value: string
  ): void => {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [filteredMissing, setFilteredMissing] =
    useState<ScannedProduct[]>(missing);

  useEffect(() => {
    const { upcCode } = filters;

    if (upcCode === '') {
      setFilteredMissing(missing);
    } else if (upcCode.length > 3) {
      setFilteredMissing(
        missing.filter(miss => (miss.upcCode || '').endsWith(upcCode))
      );
    }
  }, [filters, missing]);

  useBreadcrumbs([
    {
      title: t('scan'),
    },
  ]);

  useEffect(() => {
    if (selectedDeliveries.length === 0 && !confirmButtonClicked)
      history.push(AppRoutes.INTRO);
  }, [selectedDeliveries.length, history, confirmButtonClicked]);

  useEffect(() => {
    setPrintInError(printError !== undefined);
  }, [printError]);

  useEffect(() => {
    if (printers?.length === 0) {
      setNoPrinterAvailable(true);
    }
  }, [printers?.length]);

  useAsync(async () => {
    if (!disableScanAgain) {
      await dispatch(getScannedProducts(tags));
    }
  }, [dispatch, tags]);

  useEffect(() => {
    if (deleteTagsHasError) {
      setDeviceError(true);
      setDeviceErrorMsg(deleteTagsHasError.body.message);
    }
  }, [deleteTagsHasError]);

  useEffect(() => {
    if (printTagHasError) {
      setPrintTagError(true);
    }
  }, [printTagHasError]);

  const checkProcessOnGoing = async (): Promise<boolean> => {
    const bisInProgress =
      (await ProcessesService.readProcessStatus({
        storeCode: store?.storeCode || '',
        filterByStoreCode: true,
        process: 'BIS',
      })) || [];

    if (bisInProgress && bisInProgress[0].isLocked) {
      setProcessLock(bisInProgress[0]);
      dispatch(setAvoidAlertInHomePage(true));
      setOnGoingModalVisibility(true);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (selectedTab === Product.UNEXPECTED) {
      if (unexpected.length === 0) {
        if (missing.length === 0) {
          setSelectedTab(Product.FOUND);
        } else {
          setSelectedTab(Product.NOT_FOUND);
        }
      }
    }
  }, [selectedTab, unexpected, missing]);

  useEffect(() => {
    if (selectedTab === Product.NOT_FOUND) {
      if (missing.length === 0) {
        if (unexpected.length === 0) {
          setSelectedTab(Product.FOUND);
        } else {
          setSelectedTab(Product.UNEXPECTED);
        }
      }
    }
  }, [selectedTab, missing, unexpected]);

  const onFinishScanClick = async (): Promise<void> => {
    setSelectedTab(Product.UNEXPECTED);
    setFinishButtonClicked(true);
    const processOnGoing = await checkProcessOnGoing();
    if (processOnGoing) return;

    try {
      const { tags: deviceTags, mode } = await ScanDevicesService.getTags({
        devId: deviceInUse?.deviceId!,
      });

      if (mode === 'no mode') {
        setFinishButtonClicked(false);
        setDeviceErrorMsg(t('error.deviceCommunication'));
        return setDeviceError(true);
      }

      if (deviceTags && deviceTags.length > 0) {
        dispatch(tagsMQTTDevice(deviceTags));
      }

      setTimeout(async () => {
        const { code, message } = await ScanDevicesService.activateScanning({
          requestBody: {
            dev_id: deviceInUse?.deviceId!,
            enable: 'False',
          },
        });

        if (code !== 'OK') {
          setDeviceErrorMsg(message);
          setFinishButtonClicked(false);
          return setDeviceError(true);
        }
      }, 1000);

      setTimeout(async () => {
        await dispatch(deleteTags());

        setIsGettingTags(false);
        setIsScanCompleted(true);
        setFinishButtonClicked(false);
      }, 2000);
    } catch {
      setFinishButtonClicked(false);
      setDeviceError(true);
    }
  };

  const openConfirmModal = async (): Promise<void> => {
    const processOnGoing = await checkProcessOnGoing();
    if (processOnGoing) return;

    setConfirmModalVisibility(true);
    if (!disableScanAgain) {
      await dispatch(getScannedProducts(tags));
    }
  };

  const onModalConfirmClick = async (): Promise<void> => {
    try {
      const confirmDeliveryTime = DateTime.now();

      setConfirmLoading(true);
      setConfirmButtonClicked(true);

      await dispatch(deleteTags());
      await dispatch(confirmDelivery()).unwrap();
      await trackInvalidUpc(invalidUpc);
      await trackConfirm(startDeliveryTime, confirmDeliveryTime);

      dispatch(initDeliveryList());
      dispatch(initDeliveryProducts());
      setConfirmLoading(false);
      setConfirmModalVisibility(false);

      history.push(AppRoutes.CONFIRM_RECAP);
    } catch {
      setConfirmLoading(false);
      setConfirmButtonClicked(false);
      setConfirmError(true);
    } finally {
      setConfirmModalVisibility(false);
    }
  };

  const onModalCloseClick = async (): Promise<void> => {
    setConfirmModalVisibility(false);
  };

  const onScanClick = async (): Promise<void> => {
    setIsScanningAgain(true);
    setScanModalVisibility(true);
  };

  const handlePrintNotFound = (productItem: ScannedProduct): void => {
    if (printers && printers.length > 0) {
      setProductToPrint({
        upcCode: productItem.upcCode!,
        currency: productItem.currency,
      });
    }

    // Avoid to display the warning modal if the user has already printed a tag
    if (disableScanAgain) {
      setPrintModalVisibility(true);
    } else {
      setWarningModalVisibility(true);
    }
  };

  const showPrintError = (visible: boolean, message?: string): void => {
    setPrint(prevState => ({
      ...prevState,
      error: {
        visible,
        message,
      },
    }));
  };

  const onPrintClick = async (): Promise<void> => {
    if (print.error.message) {
      showPrintError(false);
    }

    if (store && store.storeCode && productToPrint) {
      try {
        const { upcCode, currency } = productToPrint;
        const reasonCode = print.reason
          .movementReasonCode as PrintReasonCodeStatus;

        const response = await dispatch(
          printLabel({
            storeCode: store.storeCode,
            layout: handlePrintLayout(
              currency || '',
              print.printer?.isRfid || false
            ),
            serialNumberPrinter: print.printer?.printerCode || '',
            upcCode: upcCode!,
            reasonCode,
          })
        ).unwrap();

        /**
         * Redux state is gonna change on the start use reader
         * if the selected printer is NO-RFID
         */
        if (print.printer?.isRfid) {
          const printedEPC = response?.epc?.toUpperCase() || '';

          ReceivingRedux.update(dispatch, {
            upcCode: upcCode!,
            tags,
            printedEPC,
          });

          setPrintModalVisibility(false);

          await trackPrint(upcCode!);
        } else {
          setPrint(prevState => ({
            ...prevState,
            data: {
              printedEPC: response.epc,
            },
          }));
        }
      } catch {
        showPrintError(true, t('error.something_went_wrong'));
      }
    }
  };

  /**
   * It's gonna be executed only if the selected printer
   * is NO-RFID and the active step of the print modal is 1
   */
  const onStartUseReaderClick = async (): Promise<void> => {
    if (print.printer && !print.printer.isRfid) {
      if (productToPrint) {
        const { upcCode } = productToPrint;
        const printedEPC = print.data.printedEPC || '';

        ReceivingRedux.update(dispatch, {
          upcCode: upcCode!,
          tags,
          printedEPC,
        });

        await trackPrint(upcCode!);
      }
    }
  };

  const handleWarningModalConfirm = (): void => {
    // handlePrintModalVisibility();
    setPrintModalVisibility(true);
    setWarningModalVisibility(false);
  };

  const handleWarningModalClose = (): void => {
    setProductToPrint(undefined);
    setWarningModalVisibility(false);
  };

  const onQuitClick = async (): Promise<void> => {
    subscribeEPCS.unsubscribe();
    history.push(AppRoutes.INTRO);
  };

  const onProductImageClick = (e: React.MouseEvent, upcCode: string): void => {
    e.preventDefault();

    const target = e.target as HTMLElement;

    if (
      typeof target.className === 'object' ||
      target.nextElementSibling instanceof SVGElement ||
      target.className.includes('Accordion')
    )
      return;

    setSelectedProduct(upcCode);
    setProductModalVisibility(true);
  };

  const onModalPrintDetailsClose = (): void => {
    setPrintModalVisibility(false);
    setProductToPrint(undefined);
  };

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    nextView: string
  ): void => {
    if (nextView) setView(nextView);
  };

  if (isLoading) {
    return <UILoader />;
  }

  return (
    <>
      <ModalAttention
        open={confirmModalIsVisibile}
        onConfirmClick={onModalConfirmClick}
        onClose={onModalCloseClick}
        messageMaxWidth="75%"
        message={t('confirmReceivingMessage')}
        loadingConfirm={confirmLoading}
        disableConfirm={confirmLoading}
      />
      <ModalDataSavedError
        $minWidth="506px"
        $minHeight="246px"
        open={isWarningModalVisible}
        onClose={handleWarningModalClose}
        title={t('payAttention')}
        message={t('scanAgainIsDisabledMessage')}
        iconType="ERROR"
      >
        <UIBox width="160px" mt={3}>
          <UIButton
            outlined
            label={t('ok')}
            disabled={printers?.length! === 0}
            onClick={handleWarningModalConfirm}
          />
        </UIBox>
      </ModalDataSavedError>
      <ModalPrintTemp
        open={openPrintModal}
        onClose={onModalPrintDetailsClose}
        productToPrint={productToPrint}
        onStartClick={onStartUseReaderClick}
        onPrintClick={onPrintClick}
      />
      <ModalScanDetailsV2
        open={openScanModal}
        onClose={(): void => setScanModalVisibility(false)}
        isScanningAgain={isScanningAgain}
        // defaultMode={
        //   !missingEpcs || missingEpcs.length === 0
        //     ? EnumMode.INVENTORY
        //     : EnumMode.FIND
        // }
        // disabledModes={
        //   !missingEpcs || missingEpcs.length === 0 ? [EnumMode.FIND] : []
        // }
        defaultMode={EnumMode.INVENTORY}
        //hideModes={[EnumMode.FIND]}
        scanAgainCounter={scanAgainCounter}
        setScanAgainCounter={setScanAgainCounter}
        setIsScanCompleted={setIsScanCompleted}
      />
      <ModalProductDetails
        open={openProductModal}
        onClose={(): void => setProductModalVisibility(false)}
        productCode={selectedProduct}
      />
      <StatusBar
        inPause={inPause}
        setPause={setPause}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabsDisabled={isGettingTags || !isScanCompleted}
      />
      <StyledProductsWrapper>
        {!selectedTab && (
          <Typography margin="10% 0 0" font="heavy" color="grey">
            {t('scanInProgress')}...
          </Typography>
        )}
        {selectedTab === Product.UNEXPECTED && (
          <ProductList
            type={Product.UNEXPECTED}
            data={unexpected}
            onProductImageClick={onProductImageClick}
            disableActions={disableActions}
          />
        )}
        {selectedTab === Product.NOT_FOUND && (
          <>
            <UIBox
              width="100%;"
              justifyContent="space-between"
              margin="20px 0"
              padding="0 24px"
            >
              <UIBox flexDirection="row">
                <ToggleButtonGroup
                  orientation="horizontal"
                  value={view}
                  exclusive
                  onChange={handleChange}
                >
                  <ToggleButton value="list" aria-label="module">
                    <ViewListIcon /> {t('receiving.list')}
                  </ToggleButton>
                  <ToggleButton value="grid" aria-label="list">
                    <ViewModuleIcon /> {t('receiving.grid')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </UIBox>
              <SearchBarLight
                className="searchUPC"
                label=""
                value={filters.upcCode}
                placeholder={t('receiving.filtersUPCCode')}
                disabled={false}
                onSearch={(): void => {}}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  onFilterChange('upcCode', e.target.value)
                }
                loading={false}
                hideButton={true}
              />
            </UIBox>
            {missing && missing.length > 0 && (
              <>
                {view === 'grid' ? (
                  <ProductList
                    type={Product.NOT_FOUND}
                    data={filteredMissing}
                    onPrintClick={handlePrintNotFound}
                    onProductImageClick={onProductImageClick}
                    disableActions={disableActions}
                  />
                ) : (
                  <ProductTable
                    type={Product.NOT_FOUND}
                    data={filteredMissing}
                  />
                )}
              </>
            )}
          </>
        )}
        {selectedTab === Product.FOUND && (
          <ProductList
            hasSubheader={found.length > 0}
            type={Product.FOUND}
            data={found}
            onProductImageClick={onProductImageClick}
          />
        )}
        {selectedTab === Product.SOLD && (
          <ProductList
            type={Product.SOLD}
            data={sold}
            onProductImageClick={onProductImageClick}
          />
        )}
      </StyledProductsWrapper>

      <AlertSnackbar
        open={noPrinterAvailable}
        setIsOpen={setNoPrinterAvailable}
        message={t('error.noPrinterAvailable')}
      />

      <AlertSnackbar
        open={printInError}
        setIsOpen={setPrintInError}
        message={t('error.printGenericError')}
      />

      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />

      <ErrorSnackbar
        open={confirmError}
        setIsOpen={setConfirmError}
        errorMessage={
          confirmDeliveryHasError?.body?.errorMessage ||
          confirmDeliveryHasError?.body
        }
      />

      <ErrorSnackbar
        open={printTagError && !!printTagHasError}
        autoHideDuration={5000}
        setIsOpen={setPrintTagError}
        errorMessage={
          printTagHasError?.body?.errorMessage || printTagHasError?.body
        }
      />

      {!isScanCompleted ? (
        <CTAContainer
          type="FINISH SCAN"
          onClick={onFinishScanClick}
          disabled={inPause}
          loading={finishButtonClicked}
        />
      ) : (
        <CTAContainer
          type="THREE_BUTTONS_RECEIVING"
          onQuitClick={onQuitClick}
          onConfirmClick={openConfirmModal}
          onScanClick={onScanClick}
          disableSecondaryButton={disableScanAgain}
          disabled={inPause}
          backButtonLabel={t('quit')}
          secondaryButtonLabel={t('scanagain')}
          mainButtonLabel={t('confirm')}
          disabledConfirm={unexpected.length !== 0}
        />
      )}
      <ModalDataSavedError
        iconType="ERROR"
        $minWidth="705px"
        $minHeight="260px"
        title={t('payAttention')}
        message={t('functionalitiesDisabled', {
          process: t(processLock?.process || ''),
          lockedBy: processLock?.lockedBy || '',
        })}
        open={onGoingModalIsVisible}
        onClose={(): void => {
          setOnGoingModalVisibility(false);
          history.push(AppRoutes.INTRO);
        }}
      >
        <UIBox mt={4} alignItems="center" width="50%">
          <UIButton
            outlined
            label={t('quit')}
            onClick={(): void => history.push(AppRoutes.INTRO)}
          />
        </UIBox>
      </ModalDataSavedError>
    </>
  );
};

PageReceivingScan.displayName = 'PageReceivingScan';

export default PageReceivingScan;
