import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AppFunctionalities, AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { UIMacroFlowMenu } from '@/components/layout/MacroFlowMenu';
import { UIButton } from '@/components/ui/Button';

import * as S from './style';
import { ApiError, ProcessesService, ProcessStatus } from '@/api/process';
import { useSelector } from '@/hooks/useSelector';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useAsync } from 'react-use';
import useHandleProcess from '@/hooks/useHandleProcess';
import useExpectionsNotification from '@/hooks/useExpectionsNotification';
import { initPosState } from '@/features/pos/posSlice';
import { initBISState } from '@/features/bis/backInStockSlice';
import {
  initMQTTTags,
  removeDeviceInUse,
} from '@/features/devices/devicesSlice';
// import { ClientStatus, useSocketContext } from '@/context/socketContext';
import { ScanDevice } from '@/types/device';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { MQTTContext } from '@/context/MQTT';
import { initSaleProducts } from '@/features/sales/salesProductsSlice';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { isBISAllowed } from '@/utils/user';

export const PageSale: FC<AppFunctionalities> = ({
  process: lockedProcess,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleProcess = useHandleProcess();
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );
  const loggedUser = useSelector(state => state.user);
  const sale = useExpectionsNotification('SLEX');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [onGoingActivities, setOnGoingActivities] =
    React.useState<boolean>(false);
  const [genericError, setGenericError] = React.useState<boolean>(false);
  const [processLocked, setProcessLocked] = React.useState<ProcessStatus>();
  const processSLEXLocked = useSelector(state => state.pos.processLocked);
  const processBISLocked = useSelector(state => state.bis.processLocked);
  const isProcessEnabled = process.env.REACT_APP_DISABLE_POS === 'false';
  const isProcessBackToStockEnabled =
    process.env.REACT_APP_DISABLE_POS === 'false' && isBISAllowed(loggedUser);

  const [attentionModalIsVisible, setAttentionModalVisibility] =
    React.useState<boolean>(false);

  // const { clientStatus, disconnect } = useSocketContext();
  const subscribeDevice = useSubscribeDeviceStatus();
  const { setDeviceIdOnMqttClient } = React.useContext(MQTTContext);

  useAsync(async () => {
    if (processSLEXLocked) {
      await handleProcess({ process: 'SLEX', isLocked: false });
      dispatch(initPosState());
    }
    if (processBISLocked) {
      await handleProcess({ process: 'BIS', isLocked: false });
      dispatch(initBISState());
    }
    // if (clientStatus === ClientStatus.CONNECTED) {
    //   await disconnect();
    // }
    const storage = sessionStorage.getItem('deviceInUse');

    const deviceInStorage = storage
      ? (JSON.parse(storage) as Required<ScanDevice>)
      : undefined;
    if (deviceInStorage) {
      sessionStorage.removeItem('deviceInUse');
      subscribeDevice.publish(deviceInStorage.deviceId, undefined);
      setDeviceIdOnMqttClient(undefined);
      dispatch(removeDeviceInUse());
    }
    dispatch(initMQTTTags());
    dispatch(initSaleProducts());
  }, [dispatch, processSLEXLocked, processBISLocked]);

  const handleSaleExceptionsClick = async (): Promise<void> => {
    try {
      setLoading(true);
      await handleProcess({ process: 'SLEX', isLocked: true });
      history.push(AppRoutes.SALE_EXCEPTIONS);
    } catch (e) {
      const error = e as ApiError;

      setLoading(false);

      if (error.status === 403) {
        setOnGoingActivities(true);

        const salesExceptionProcess =
          (await ProcessesService.readProcessStatus({
            storeCode,
            process: 'SLEX',
            filterByStoreCode: true,
          })) || [];

        if (salesExceptionProcess && salesExceptionProcess[0].isLocked) {
          setProcessLocked(salesExceptionProcess[0]);
        }
      } else {
        setGenericError(true);
      }
    }
  };

  const handleBISClick = async (): Promise<void> => {
    try {
      setLoading(true);
      await handleProcess({ process: 'BIS', isLocked: true });
      history.push(AppRoutes.SALE_BIS);
    } catch (e) {
      const error = e as ApiError;

      setLoading(false);

      if (error.status === 403) {
        setOnGoingActivities(true);

        const bisProcess =
          (await ProcessesService.readProcessStatus({
            storeCode,
            process: 'BIS',
            filterByStoreCode: true,
          })) || [];

        if (bisProcess && bisProcess[0].isLocked) {
          setProcessLocked(bisProcess[0]);
        }
      } else {
        setGenericError(true);
      }
    }
  };

  return (
    <>
      <Modal
        iconType="ERROR"
        $minWidth="420px"
        $minHeight="230px"
        title={t('tryAgain') + '!'}
        message={t('onGoingActivitiesMessage', {
          user: processLocked?.lockedBy,
        })}
        open={onGoingActivities}
        onClose={(): void => {
          setOnGoingActivities(false);
          setProcessLocked(undefined);
        }}
      />
      <UIMacroFlowMenu title={t('menu.title')}>
        <S.Link
          to={AppRoutes.SALE_ITEMS}
          disabled={lockedProcess?.isLocked || loading}
        >
          <UIButton
            disabled={lockedProcess?.isLocked || loading}
            label={t('menu.checkoutLight')}
            outlined={false}
          />
        </S.Link>
        {isProcessEnabled && (
          <S.Link
            to={AppRoutes.SALE}
            disabled={
              (lockedProcess?.process === 'SLEX' &&
                lockedProcess?.isLocked &&
                lockedProcess.lockedBy !== loggedUser.username) ||
              loading
            }
          >
            <UIButton
              label={t('menu.salesExceptions')}
              loading={loading}
              disabled={lockedProcess?.isLocked || loading}
              outlined={false}
              onClick={handleSaleExceptionsClick}
              startIcon={
                <>{sale.exceptionsOccurred && <sale.ExceptionsIcon />}</>
              }
            />
          </S.Link>
        )}
        {isProcessBackToStockEnabled && (
          <UIButton
            label={t('menu.bis')}
            loading={loading}
            disabled={
              (lockedProcess?.process === 'BIS' &&
                lockedProcess?.isLocked &&
                lockedProcess.lockedBy !== loggedUser.username) ||
              loading
            }
            onClick={(): void => setAttentionModalVisibility(true)}
            outlined={false}
          />
        )}
      </UIMacroFlowMenu>
      <ErrorSnackbar
        open={genericError}
        setIsOpen={(): void => setGenericError(false)}
        errorMessage={t('error.openSalesExceptions')}
      />
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={handleBISClick}
        onClose={(): void => setAttentionModalVisibility(false)}
        messageMaxWidth="100%"
        message={t('activitiesClosed')}
      />
    </>
  );
};

export default PageSale;
